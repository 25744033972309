import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"

const DisclaimerPage = ({ data, location }) => {
    return(
        <Layout>
            <SEO
                title="Disclaimer"
                pathname={location.pathname}
            />
            <Hero title="Disclaimer" description="" image={data.hero.childImageSharp.fluid} />
            <Section>
                <p>This disclaimer (&quot;Disclaimer&quot;, &quot;Agreement&quot;) is an agreement between Neon Pixel Studios (&quot;Neon Pixel Studios&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Disclaimer sets forth the general guidelines, terms and conditions of your use of the <a target="_blank" rel="nofollow noreferrer" href="https://pocketshutterbug.com">pocketshutterbug.com</a> website and any of its products or services (collectively, &quot;Website&quot; or &quot;Services&quot;).</p>
                <h2>Representation</h2>
                <p>Any views or opinions represented on the Website are personal and belong solely to Neon Pixel Studios and do not represent those of people, institutions or organizations that the owner may or may not be associated with in professional or personal capacity unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.</p>
                <h2>Content and postings</h2>
                <p>You may not modify, print or copy any part of the Website. Inclusion of any part of this Website in another work, whether in printed or electronic or another form or inclusion of any part of the Website in another website by embedding, framing or otherwise without the express permission of Neon Pixel Studios is prohibited.</p>
                <h2>Compensation and sponsorship</h2>
                <p>This Website accepts forms of advertising, sponsorship, paid insertions or other forms of compensation. Neon Pixel Studios is compensated to provide opinion on products, services, websites and various other topics. Even though Neon Pixel Studios receives compensation for our posts or advertisements, we always give our honest opinions, findings, beliefs, or experiences on those topics or products. The views and opinions expressed on the Website are purely of Neon Pixel Studios. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question. Sponsored content, advertising space or post will always be identified as such. Some of the links on the Website may be &quot;affiliate links&quot;. This means if you click on the link and purchase an item, Neon Pixel Studios will receive an affiliate commission. Furthermore, Neon Pixel Studios is a participant in the Amazon Associates program, an affiliate advertising program designed to provide a means to earn advertising fees by advertising and linking to Amazon properties.</p>
                <h2>Indemnification and warranties</h2>
                <p>While we have made every attempt to ensure that the information contained on the Website is correct. Neon Pixel Studios is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information on the Website is provided &quot;as is&quot;, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will Neon Pixel Studios be liable to you or anyone else for any decision made or action taken in reliance on the information on the Website or for any consequential, special or similar damages, even if advised of the possibility of such damages. Information on the Website is for general information purposes only and is not intended to provide any type of professional advice. Please seek professional assistance should you require it. Furthermore, information contained on the Website and any pages linked to and from it are subject to change at any time and without warning.</p>
                <p>We reserve the right to modify this Disclaimer relating to the Website, products or services at any time, effective upon posting of an updated version of this Disclaimer on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.</p>
                <h2>Acceptance of this disclaimer</h2>
                <p>You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing the Website you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to use or access the Website.</p>
                <h2>Contacting us</h2>
                <p>If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may send an email to su&#112;&#112;&#111;r&#116;&#64;&#112;o&#99;&#107;et&#115;&#104;u&#116;t&#101;r&#98;&#117;&#103;&#46;&#99;o&#109;</p>
                <p>This document was last updated on November 1, 2020</p>
            </Section>
        </Layout>
    )
}

export default DisclaimerPage

export const disclaimerPageListQuery = graphql`
    query {
        hero: file(relativePath: { eq: "sign-up-8_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`