import React from "react"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

const Wrapper = styled.section`
    ${tw`w-full m-0 p-0 bg-psb-dark-gray-400 text-white`}
`

const Container = styled.div`
    ${tw`container flex flex-row items-center w-11/12 mx-auto px-5 py-8`}
`

const Left = styled.div`
    ${tw`lg:w-4/5`}
`

const Title = styled.h1`
    ${tw`text-white`}
`

const Description = styled.p`
    ${tw`text-white`}
`

const Right = styled.div`
    ${tw`lg:w-1/5 hidden lg:block`}
`

const Hero = ({ title, description, image }) => {
    return(
        <Wrapper>
            <Container>
                <Left>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </Left>
                <Right>
                <Img fluid={image} />
                </Right>
            </Container>
        </Wrapper>
    )
}

export default Hero